/* eslint eqeqeq: "off" */
import React, { useState, useEffect, memo } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import connectStore from "../state/connectStore"
import Layout from "../ui/Layout"
import {
  Button,
  Input,
} from "semantic-ui-react"
import QRCodeLoader from "../ui/QRCodeLoader"
import { v4 } from "uuid"
import Color from "../style/Color"

const CODE_LIST_STYLE = { maxWidth: "600px", maxHeight: "300px", overflow: "auto" };
const PREVIEW_STYLE = { maxWidth: "600px", maxHeight: "600px", overflow: "auto" };

const num = (n, def=1) => isNaN(n) || n == 0 ? def : parseFloat(n);

const getCanvasUrl = () => {
  const canvas = document.getElementById("grid-canvas");
  if(canvas == null) {
    return "";
  }
  return canvas.toDataURL();
};

const QRGrid = memo(({ versionId, ROWS, COLS, QR_SIZE, addOrderId }) => {
  if(ROWS == 0 || COLS == 0 || QR_SIZE == 0) {
    return null;
  }
  return (
    [...Array(ROWS)].map((_, i) => (
      [...Array(COLS)].map((__, j) => (
        <QRCodeLoader key={i*COLS + j} versionId={versionId} className="single-canvas" size={QR_SIZE} addOrderId={addOrderId}/>
      ))
    ))
  );
});

const Preview = memo(({orderIds, SHEET_WIDTH, SHEET_HEIGHT}) => (
  orderIds.length > 0 && <canvas id="grid-canvas" width={SHEET_WIDTH} height={SHEET_HEIGHT} /> 
));

const Page = ({ location, qrSheetProps={}, setQrSheetProps }) => {
  const { ppi=300, rows=10, cols=10, qrSizeIn=0.86, qrSpacingIn=1.125 } = qrSheetProps;

  // Calculate all our sizes based on our state
  const PPI = num(ppi, 300);
  const ROWS = num(rows, 1);
  const COLS = num(cols, 1);
  const QR_SIZE_IN = num(qrSizeIn, 0.86);
  const QR_SPACING_IN = num(qrSpacingIn, 1.125);

  const QR_SIZE = Math.floor(PPI * QR_SIZE_IN);
  const QR_SPACING = Math.floor(PPI * QR_SPACING_IN);

  const SHEET_WIDTH = QR_SIZE*ROWS + (QR_SPACING-QR_SIZE)*(ROWS-1);
  const SHEET_HEIGHT = QR_SIZE*COLS + (QR_SPACING-QR_SIZE)*(COLS-1);

  // Define our actual state
  const [renderedTwice, setRenderedTwice] = useState(false);
  const [versionId, setVersionId] = useState(0);
  const [orderIds, setOrderIds] = useState([]);
  const tempOrderIds = [];

  const generateQRSheet = () => {
    const destCanvas = document.getElementById("grid-canvas");
    if(destCanvas == null) {
      return;
    }
    const destCtx = destCanvas.getContext('2d');
    const canvases = document.getElementsByClassName("single-canvas");
    for(let i = 0; i < ROWS; i++) {
      for(let j = 0; j < COLS; j++) {
        const index = i * COLS + j;
        if(index >= canvases.length) {
          continue;
        }
        const sourceCanvas = canvases[index];
        destCtx.drawImage(sourceCanvas, 0 + QR_SPACING*i, 0 + QR_SPACING*j, QR_SIZE, QR_SIZE);
      }
    }
    setRenderedTwice(false);
  };

  const addOrderId = id => {
    tempOrderIds.push(id);
    if(tempOrderIds.length == COLS*ROWS) {
      setOrderIds([...tempOrderIds]);
      generateQRSheet();
    }
  };

  useEffect(() => {
    if(!renderedTwice) {
      setRenderedTwice(true);
    }
  }, [renderedTwice, setRenderedTwice]);

  return (
    <Layout location={location} validate={false} showOrder={false}>
      <div>
        <Input type="number" label="Pixels Per Inch (PPI)" value={ppi} onChange={(e, { value }) => setQrSheetProps({ ppi: value })} />
        <br/><br/>
        <Input type="number" label="QR Size (in.)" value={qrSizeIn} onChange={(e, { value }) => setQrSheetProps({ qrSizeIn: value })} />
        <br/><br/>
        <Input type="number" label="QR Spacing (in. center to center)" value={qrSpacingIn} onChange={(e, { value }) => setQrSheetProps({ qrSpacingIn: value})} />
        <br/><br/>
        <Input type="number" label="Rows" value={rows} onChange={(e, { value }) => setQrSheetProps({ rows: value })} />
        <br/><br/>
        <Input type="number" label="Cols" value={cols} onChange={(e, { value }) => setQrSheetProps({ cols: value})} />
      </div>
      <br/>
      <div className="super-centered">
        <Button color={Color.accent} onClick={() => {
          setVersionId(versionId+1);
        }}>1. Generate QR Codes</Button>
        <Button color={Color.accent} onClick={generateQRSheet} disabled={orderIds.length == 0}>2. Generate QR Sheet</Button>
        <Button as="a" color={Color.accent} href={getCanvasUrl()} download={"qr-sheet-" + v4() + ".png"} disabled={orderIds.length == 0}>3. Download</Button>
      </div>
      <br/><br/>
      <div className="hide">
        <QRGrid ROWS={ROWS} COLS={COLS} versionId={versionId} QR_SIZE={QR_SIZE} addOrderId={addOrderId}/>
      </div>
      <div className="super-centered">
        {orderIds.length == 0 ? <h3>No Codes</h3> : <h3>{orderIds.length + " Codes"}</h3>}
      </div>
      <br/>
      <div className="super-centered">
        <div style={CODE_LIST_STYLE}>
          {orderIds.sort().map((orderId, i) => <div key={i}>{orderId}</div>)}
        </div>
      </div>
      <br/><br/>
      <div className="super-centered">
        <h3>Image Preview</h3>
      </div>
      <br/>
      <div className="super-centered">
        <div style={PREVIEW_STYLE}>
          <Preview orderIds={orderIds} SHEET_WIDTH={SHEET_WIDTH} SHEET_HEIGHT={SHEET_HEIGHT}/>
        </div>
      </div>
    </Layout>
  );
};

export default withAuthenticator(connectStore(Page));
